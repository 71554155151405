import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import LoaderImg from './assets/img/cromwelicon.png';

const Home = lazy(() => import("./pages/Home"));
const ClientCentered = lazy(() => import("./pages/ClientCentered"));
const UniqueCulture = lazy(() => import("./pages/UniqueCulture"));
const Collaboration = lazy(() => import("./pages/Collaboration"));
const Services = lazy(() => import("./pages/Services"));
const TheTeam = lazy(() => import("./pages/TheTeam"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Careers = lazy(() => import("./pages/Careers"));
const News = lazy(() => import("./pages/News"));

const OilandGas = lazy(() => import("./pages/OilandGas"));
const Corporate = lazy(() => import("./pages/Corporate"));
const Maritime = lazy(() => import("./pages/Maritime"));
const Banking = lazy(() => import("./pages/Banking"));
const Commerce = lazy(() => import("./pages/Commerce"));
const RealEstate = lazy(() => import("./pages/RealEstate"));
const EnvironmentalLaw = lazy(() => import("./pages/EnvironmentalLaw"));
const SportsRepresentation = lazy(() => import("./pages/SportsRepresentation"));
const Aviation = lazy(() => import("./pages/Aviation"));
const Media = lazy(() => import("./pages/Media"));
const EntertainmentLaw = lazy(() => import("./pages/EntertainmentLaw"));
const Consultancy = lazy(() => import("./pages/Consultancy"));
const InvestigativeServices = lazy(() => import("./pages/InvestigativeServices"));
const Notary = lazy(() => import("./pages/NotaryPublic"));

const SPartner = lazy(() => import("./pages/SPartner"));
const NPartner = lazy(() => import("./pages/NPartner"));
const DPartner = lazy(() => import("./pages/DPartner"));
const OPartner = lazy(() => import("./pages/OPartner"));

const BlogOne = lazy(() => import("./pages/BlogOne"));
const BlogTwo = lazy(() => import("./pages/BlogTwo"));
const BlogThree = lazy(() => import("./pages/BlogThree"));
const BlogFour = lazy(() => import("./pages/BlogFour"));
const BlogFive = lazy(() => import("./pages/BlogFive"));
const BlogSix = lazy(() => import("./pages/BlogSix"));
const BlogSeven = lazy(() => import("./pages/BlogSeven"));

function App() {
  return (
    <div className="App">
      <Router>
        <Suspense
          fallback={
            <div className="preloader-div">
              <img src={LoaderImg} alt="" /> <br /><br  />

              <span className="preloader-white">Trust |</span> <span className="preloader-red">Sound Advice |</span> <span className="preloader-white">Innovation</span>
            </div>
          }
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/client-centered-closely-knit-team" component={ClientCentered} />
            <Route exact path="/a-unique-culture" component={UniqueCulture} />
            <Route exact path="/collaboration" component={Collaboration} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/the-team" component={TheTeam} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/careers" component={Careers} />
            <Route exact path="/news" component={News} />

            <Route exact path="/oil-and-gas" component={OilandGas} />
            <Route exact path="/corporate" component={Corporate} />
            <Route exact path="/maritime" component={Maritime} />
            <Route exact path="/banking-and-financial-services" component={Banking} />
            <Route exact path="/commerce-and-business" component={Commerce} />
            <Route exact path="/real-estate" component={RealEstate} />
            <Route exact path="/environmental-law" component={EnvironmentalLaw} />
            <Route exact path="/sports-representation" component={SportsRepresentation} />
            <Route exact path="/aviation" component={Aviation} />
            <Route exact path="/media" component={Media} />
            <Route exact path="/entertainment-law" component={EntertainmentLaw} />
            <Route exact path="/consultancy-and-research" component={Consultancy} />
            <Route exact path="/investigative-services" component={InvestigativeServices} />
            <Route exact path="/notary-public" component={Notary} />

            <Route exact path="/profile-of-seth-asante-partner" component={SPartner} />
            <Route exact path="/profile-of-nana-aba-akwaaba-acquaah" component={NPartner} />
            <Route exact path="/profile-of-dennis-frimpong-agyebeng" component={DPartner} />
            <Route exact path="/profile-of-odei-krow-associate" component={OPartner} />

            <Route exact path="/kissi-agyebeng-appointed-special-prosecutor-subject-to-parliamentary-approval" component={BlogOne} />
            <Route exact path="/managing-partner-of-cromwell-gray-appointed-chairman-of-electronic-communications-tribunal-copy" component={BlogTwo} />
            <Route exact path="/managing-partner-of-cromwell-gray-appointed-chairman-of-electronic-communications-tribunal" component={BlogThree} />
            <Route exact path="/white-case-cromwell-gray-llp-advise-ghana-national-petroleum-corporation-on-landmark-lng-project" component={BlogFour} />
            <Route exact path="/cromwell-gray-llp-issues-press-statement-on-behalf-of-dsp-emmanuel-a-basintale-and-four-other-police-officers" component={BlogFive} />
            <Route exact path="/cromwell-gray-llp-issues-press-statement-on-behalf-of-asamoah-gyan" component={BlogSix} />
            <Route exact path="/africa-investigates-training-series" component={BlogSeven} />

            <Redirect to="/" />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
